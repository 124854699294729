import {
  CrossSellInput,
  CrossSellType,
  CrossSellAction,
  CheckoutType,
} from 'types';

export function defaultCrossSellUrl(input: CrossSellInput) {
  const { baseUrl, uuid, dcSessionId, qual } = input;
  const urlQual = encodeURIComponent(btoa(JSON.stringify(qual)));
  return `${baseUrl}/qualifying?uuid=${uuid}&sessionId=${dcSessionId}&qual=${urlQual}`;
}

export function spectrumCrossSellUrl(input: CrossSellInput): string {
  const unit = input.address.unit || '';
  return `https://etail-customer.spectrum.com/address/localization?v=BVINE&affpn=866-282-1155&a=${encodeURIComponent(
    input.address.street
  )}&u=${encodeURIComponent(unit)}&z=${encodeURIComponent(
    input.address.postalCode
  )}&TransID=${input.dcFwsTid}`;
}

export function msoCrossSellUrl(input: CrossSellInput): string {
  const { providerConfig } = input;
  if (providerConfig.providerCode === 'spectrum') {
    return spectrumCrossSellUrl(input);
  }
  return defaultCrossSellUrl(input);
}

export function allLeadMode() {
  return CheckoutType.LEAD_MODE;
}

export function allCallMode() {
  return CheckoutType.CALL_MODE;
}

export function getDefaultCrossSellAction(
  input: CrossSellInput
): CrossSellAction {
  if (input.providerConfig) {
    if (input.providerConfig?.providerCode === 'spectrum') {
      const spectrumUrl = spectrumCrossSellUrl(input);
      return {
        crossSellType: CrossSellType.CROSS_SELL_REDIRECT,
        crossSellUrl: spectrumUrl,
      } as CrossSellAction;
    }
    return {
      crossSellType: CrossSellType.CROSS_SELL_RESKIN,
      reskinProviderConfig: input.providerConfig,
    } as CrossSellAction;
  }
}

/*
export function msoProvidersWithout(excludeProvider: ProviderConfig): ProviderConfig[] {
  return providersOfType(ProviderType.MSO).filter((provider) => {
    return provider.code !== excludeProvider.code;
  });
}
export function lecProvidersWithout(excludeProvider: ProviderConfig): ProviderConfig[] {
  return providersOfType(ProviderType.LEC).filter((provider) => {
    return provider.code !== excludeProvider.code;
  });
}
export function providersOfType(providerType: ProviderType): ProviderConfig[] {
  return ProviderList.filter(provider => provider.type === providerType);
}
*/
