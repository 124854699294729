import { ProviderConfig, ProviderTypes } from 'types';
import { AstoundTheme } from 'config/themes';

export const Astound: ProviderConfig = {
  providerType: ProviderTypes.MSO,
  providerCode: 'astound',
  dcCode: 'Astound',
  name: 'Astound',
  displayName: 'Astound',
  reskinTheme: AstoundTheme,
  reskinTitle: 'Astound Authorized Dealer',
  showPricingDisclaimer: false,
  broadbandDisplayType: 'updater_generated',
};
